import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import Kiji from "@components/kiji-link";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <section className='honbun'>
      <p><strong parentName="p">{`補足：`}</strong></p>
      <p>{`実際のMIXで「配置」の作業をするときは、`}<br parentName="p"></br>{`
`}{`「大きさ」と同じで、`}<strong parentName="p">{`全てリファレンスと同じ配置`}</strong>{`にします。`}<br parentName="p"></br>{`
`}{`これならば、迷いようがありません。`}</p>
      <p>{`もし、「リファレンスにない楽器がある」など、`}<br parentName="p"></br>{`
`}{`リファレンスと比べられない場合は、`}<br parentName="p"></br>{`
`}{`次回から話す配置の原則に従うと`}<br parentName="p"></br>{`
`}{`迷うことなく配置をすることができます。`}</p>
    </section>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      